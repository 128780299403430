// WelcomePage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import './WelcomePage.css';
import logo from '../assets/Images/logo.png';
import { firebaseConfig } from '../utils/firebaseHelper';
import feature1Image from '../assets/Images/feature1.png';
import feature2Image from '../assets/Images/feature2.png';
import feature3Image from '../assets/Images/feature3.png';
import playStoreLogo from '../assets/Images/playstorelogo.png';
import appStoreLogo from '../assets/Images/appstorelogo.png';

const WelcomePage = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

 

  const handleGetStarted = () => {
    navigate('/signup');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <div className="welcome-page">
      <div className="welcome-content">
        <header>
          <img src={logo} alt="On Tour Logo" className="logo" />
          <button className="sign-in-btn" onClick={handleSignIn}>
            Sign In
          </button>
        </header>
        <main>
          <h1>Welcome to On Tour</h1>
          <h2>Click Below to create your account!</h2>
          <div className="username-input">
           
            <button className="get-started-btn" onClick={handleGetStarted}>
              Get Started
            </button>
            
          </div>
          <div className="app-download">
            <p>Get On Tour App on Google Play and App Store</p>
            <div className="app-store-logos">
              <img src={playStoreLogo} alt="Google Play" className="playstorelogo" />
              <img src={appStoreLogo} alt="App Store" className="appstorelogo" />
            </div>
          </div>
        </main>
      </div>
      <div className="features-section">
        <div className="feature">
          <div className="feature-text">
            <h3>Explore Daily Tours</h3>
            <p>Discover new destinations and plan your dream vacation with On Tour. From exotic beaches to historic cities, we've got you covered.</p>
          </div>
          <div className="feature-image">
            <img src={feature1Image} alt="Explore the World" />
          </div>
        </div>
        <div className="feature">
          <div className="feature-image">
            <img src={feature2Image} alt="Connect with Travelers" />
          </div>
          <div className="feature-text">
            <h3>Profile With Tour Dates</h3>
            <p>Meet like-minded travelers, share your experiences, and make unforgettable memories together.</p>
          </div>
        </div>
        <div className="feature">
          <div className="feature-text">
            <h3>Create Tours</h3>
            <p>Get personalized travel recommendations based on your preferences and interests. On Tour makes it easy to find the perfect destination for you.</p>
          </div>
          <div className="feature-image">
            <img src={feature3Image} alt="Personalized Recommendations" />
          </div>
        </div>
      </div>
      <footer>
        <ul>
        <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
        </ul>
      </footer>
    </div>
  );
};

export default WelcomePage;
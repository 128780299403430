import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage, removeUserDataFromLocalStorage } from '../../utils/firebaseHelper';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { getFirebaseApp } from '../../utils/firebaseHelper';
import './Dashboard.css';
import playstorelogo from '../../assets/Images/playstorelogo.png';
import appstorelogo from '../../assets/Images/appstorelogo.png';
import logo from '../../assets/Images/logo.png';
import UserContext from '../../context/UserContext';
import Layout from '../Layout';
import { getAuth } from 'firebase/auth';
import { arrayUnion } from 'firebase/firestore';



export default function Dashboard() {
  const { userData, setUserData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (!userData) {
      navigate('/login');
    }
  }, [userData, navigate]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const membershipTier = userData.membershipTier || 'Free';
  const membershipEndDate = userData.membershipEndDate;
  


  const tableData = [
    { feature: 'Create Unlimited Tours', free: '✓', pro: '✓', elite: '✓',  },
    { feature: 'Artists per Tour', free: '1', pro: '5', elite: 'Unlimited' },
    { feature: 'Crew Members per Tour', free: '2', pro: '3', elite: 'Unlimited' },
    { feature: 'Account Verification', free: '✕', pro: '✓', elite: '✓' },
    { feature: 'Send Messages to Other Accounts', free: '✕', pro: '✓' , elite: '✓'},
    { feature: 'See users Tour Dates', free: '✕', pro: '✓', elite: '✓' },
    { feature: 'Ideal For Managing', free: 'Solo Artist', pro: 'A Band', elite: 'Multiple Bands' },
    { feature: 'Price', free: 'Free', pro: '₹499 per month' , elite: '₹999 per month',},
  ];

  const handleUpgrade = async (membershipTier) => {
    const db = getFirestore(getFirebaseApp());
    const auth = getAuth();
    const currentUser = auth.currentUser;
  
    // Check if the user is authenticated
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }
  
    const usersCollectionRef = collection(db, 'users');
    const userQuery = query(usersCollectionRef, where('userId', '==', currentUser.uid));
    const userQuerySnapshot = await getDocs(userQuery);
  
    if (!userQuerySnapshot.empty) {
      const userDocSnapshot = userQuerySnapshot.docs[0];
      const userDocRef = userDocSnapshot.ref;
      const userData = userDocSnapshot.data();
  
      // Fetch the payment details from the userData
      const { paymentInfo } = userData;
  
      const options = {
        key: 'rzp_test_X1matWmcxvSzhu',
        amount: membershipTier === 'Pro' ? 49900 : 99900, // Amount in paise
        currency: 'INR',
        name: 'On Tour',
        description: `Upgrade to ${membershipTier} Membership`,
        image: 'https://ontour.co.in/static/media/logo.004c25c8bff3fe32ff88.png',
        handler: async (response) => {
          console.log('Payment Response:', response);
        
          const paymentId = response.razorpay_payment_id;
        
          try {
            // Make an HTTP request to fetch the payment details
            const apiKey = 'rzp_test_X1matWmcxvSzhu';
            const apiSecret = 'OAMDGNjlwqOnOgrq0vNp7uc2';
            const auth = btoa(`${apiKey}:${apiSecret}`);
            const url = `https://api.razorpay.com/v1/payments/${paymentId}`;
            const requestOptions = {
              method: 'GET',
              headers: {
                'Authorization': `Basic ${auth}`
              }
            };
        
            const response = await fetch(url, requestOptions);
            const paymentDetails = await response.json();
            console.log('Payment Details:', paymentDetails);
        
            // Extract the relevant fields from the payment details
            const { method, status, amount, currency } = paymentDetails;
        
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
        
            const startDate = new Date();
        
            const paymentInfo = {
              paymentId,
              method,
              status,
              amount,
              currency,
            };
        
            const paymentData = {
              paymentId,
              method,
              membershipType: membershipTier,
              membershipStartDate: startDate.toISOString(),
              membershipEndDate: endDate.toISOString(),
              amount,
            };
        
            const updateData = {
              membershipTier,
              membershipStartDate: startDate.toISOString(),
              membershipEndDate: endDate.toISOString(),
              paymentInfo,
              paymentHistory: arrayUnion(paymentData),
            };
        
            console.log('Update Data:', updateData);
        
            await updateDoc(userDocRef, updateData);
            console.log('User document updated successfully');
        
            // Update the userData state with the updated membership details and payment info
            setUserData((prevUserData) => ({
              ...prevUserData,
              ...updateData,
            }));
          } catch (error) {
            console.error('Error fetching payment details:', error);
          }
        },
        
        prefill: {
      name: userData.fullName,
      email: userData.email,
      contact: userData.phoneNumber,
    },
    notes: {
      address: 'On Tour Membership Upgrade',
    },
    theme: {
      color: '#E2384D',
    },
    method: ['upi', 'card', 'netbanking'],// Specify the payment method here (e.g., 'netbanking', 'card', 'upi')
  };
  
   
  // Update the options based on the payment method
if (paymentInfo) {
  if (paymentInfo.method === 'upi') {
    options.prefill.vpa = paymentInfo.upiId;
  } else if (paymentInfo.method === 'card') {
    options.prefill.card = {
      number: paymentInfo.card.number,
      expiry: paymentInfo.card.expiry,
      cvv: paymentInfo.card.cvv,
    };
  } else if (paymentInfo.method === 'netbanking') {
    options.prefill.bank = paymentInfo.bank;
    options.prefill.account_number = paymentInfo.accountNumber;
    options.prefill.ifsc_code = paymentInfo.ifscCode;
  }
}

  const rzp = new window.Razorpay(options);
  rzp.open();
} else {
  // User document not found, handle the case accordingly (e.g., create a new document)
  console.error('User document not found');
}
};

  return (
    <Layout>
      <div className="main-content">
        <div className="content">
        <h2>Membership Info</h2>
          <p>
            Your current membership tier is <span className="membership-tier">{membershipTier}</span>.
          </p>
          {membershipEndDate && (
            <p>
              Membership Ending Date:{' '}
              {new Date(membershipEndDate).toLocaleDateString()}
            </p>
)}
          <table className="membership-table">
            <thead>
              <tr>
                <th>Features</th>
                <th>Free</th>
                <th>Pro</th>
                <th>Elite</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.feature}</td>
                  <td>{row.free}</td>
                  <td>{row.pro}</td>
                  <td>{row.elite}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td>
                {membershipTier !== 'Pro' && (
                    <button className="upgrade-button" onClick={() => handleUpgrade('Pro')}>Upgrade to Pro</button>
                  )}
                  {membershipTier === 'Pro' && (
                    <button className="cancel-button">Cancel Membership</button>
                  )}
                </td>
                <td>
                  {membershipTier !== 'Elite' && (
                    <button className="upgrade-button" onClick={() => handleUpgrade('Elite')}>Upgrade to Elite</button>
                  )}
                  {membershipTier === 'Elite' && (
                    <button className="cancel-button">Cancel Membership</button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="app-download">
            <p>Get On Tour App on Google Play and App Store</p>
            <div className="app-store-logos">
              <img src={playstorelogo} alt="Google Play" className="playstorelogo" />
              <img src={appstorelogo} alt="App Store" className="appstorelogo" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
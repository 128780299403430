import React from 'react';
import './TermsAndConditions.css'; // Importing CSS file for styling
import logo from '../assets/Images/logo.png';

function TermsAndConditions() {
    return (
      <div className="terms-page">
        <header className="top-bar">
          <img src={logo} alt="On Tour Logo" className="logo" />
          <button className="sign-in-btn">Sign In</button>
        </header>
        <div className="container">
          <h1 className="title">Terms and Conditions</h1>
      <div className="content">
        <p>
          These Terms and Conditions ("Terms", "Agreement") are an agreement between you ("User", "you", "your") and Forte Technologies ("Company", "we", "our", "us"). This Agreement sets forth the general terms and conditions of your use of the "On Tour" mobile application and any of its products or services (collectively, "App" or "Services").
        </p>
        <h2>1. Interpretation and Definitions</h2>
        <p>
          <strong>Interpretation:</strong> The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        <p>
          <strong>Definitions:</strong> For the purposes of these Terms and Conditions:
        </p>
        <ul>
          <li><strong>App:</strong> refers to the "On Tour" mobile application, developed and owned by Forte Technologies, accessible from [Your App Store URL].</li>
          <li><strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Forte Technologies.</li>
          <li><strong>Service:</strong> refers to the App.</li>
          <li><strong>User:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
          <li><strong>Tour Manager:</strong> refers to the user who organizes and schedules tours for artists and crew members.</li>
          <li><strong>Artist:</strong> refers to the user who is the performer or main attraction of the tour.</li>
          <li><strong>Crew Members:</strong> refers to the users who are responsible for assisting the artist during the tour.</li>
        </ul>
        <h2>2. Services</h2>
        <p>Our app provides a platform for Tour Managers to create everyday schedules for tours involving Artists and Crew Members.</p>
        <h2>3. User Responsibilities</h2>
        <ul>
          <li><strong>Account:</strong> You must create an account to use our Services. You are responsible for maintaining the confidentiality of your account and password.</li>
          <li><strong>Accuracy:</strong> You agree to provide accurate and complete information when using our Services.</li>
        </ul>
        <h2>4. Intellectual Property</h2>
        <p>
          <strong>Content:</strong> All content on this app, including but not limited to text, graphics, logos, images, and software, is the property of Forte Technologies or its content suppliers.
        </p>
        <p>
          <strong>License:</strong> By using our Services, you grant us a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, save, modify, create derivative works, and distribute your content.
        </p>
        <h2>5. Limitation of Liability</h2>
        <p>
          <strong>Disclaimer:</strong> Our Services are provided "as is," with all faults, and we make no express or implied warranties.
        </p>
        <p>
          <strong>Limitation of Liability:</strong> In no event shall Forte Technologies be liable for any indirect, incidental, special, consequential, or punitive damages.
        </p>
        <h2>6. Governing Law</h2>
        <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.</p>
        <h2>7. Changes to These Terms</h2>
        <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>
        <h2>8. Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@ontour.co.in">support@ontour.co.in</a>.</p>
        </div>
      </div>

      <footer className="footer">
        <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
        <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@ontour.co.in">support@ontour.co.in</a>.</p>    
        <ul>
          <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
        </ul>
      </footer>

    </div>
  );
}

export default TermsAndConditions;

import React, { useContext } from 'react';
import UserContext from '../context/UserContext';
import logo from '../assets/Images/logo.png';

const TopBar = () => {
  const { userData } = useContext(UserContext);

  if (!userData) {
    return null; // or return a loading placeholder
  }

  return (
    <div className="top-bar">
      <img src={logo} alt="On Tour Logo" className="logo" />
      <div className="user-info">
        <img src={userData.profilePhotoUrl} alt="Profile" className="profile-picture" />
        <span className="fullname">{userData.fullName}</span>
      </div>
    </div>
  );
};

export default TopBar;
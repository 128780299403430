import React, { useState, useContext, useEffect } from 'react';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getFirebaseApp } from '../../utils/firebaseHelper';
import { getAuth, updateEmail, sendPasswordResetEmail } from 'firebase/auth';
import { uploadProfilePhoto, checkUsernameUniqueness, sendEmailOtp, updateUserEmail } from '../../utils/actions/authActions';
import UserContext from '../../context/UserContext';
import Layout from '../Layout';
import './AccountDetails.css';

export default function AccountDetails() {
  const { userData, setUserData } = useContext(UserContext);
  const [newName, setNewName] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [newProfilePhoto, setNewProfilePhoto] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (userData) {
      setNewName(userData.fullName);
      setNewUsername(userData.username);
      setNewEmail(userData.email);
    }
  }, [userData]);

  const handleNameChange = (e) => {
    setNewName(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setNewUsername(e.target.value);
  };

  const handleProfilePhotoChange = (e) => {
    setNewProfilePhoto(e.target.files[0]);
  };

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSendOtp = async () => {
    try {
      await sendEmailOtp(newEmail);
      setOtpSent(true);
      alert('OTP sent to your email');
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Failed to send OTP. Please try again.');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const db = getFirestore(getFirebaseApp());
    const userRef = doc(db, 'users', userData.userId);

    try {
      if (newProfilePhoto) {
        const downloadURL = await uploadProfilePhoto(newProfilePhoto, userData.userId);
        await updateDoc(userRef, { profilePhotoUrl: downloadURL });
      }

      if (newName !== userData.fullName) {
        await updateDoc(userRef, { fullName: newName });
      }

      if (newUsername !== userData.username) {
        const isUsernameUnique = await checkUsernameUniqueness(newUsername);
        if (isUsernameUnique) {
          await updateDoc(userRef, { userName: newUsername });
        } else {
          alert('Username is already taken. Please choose a different username.');
          return;
        }
      }

      if (newEmail !== userData.email && otpSent && otp) {
        await updateUserEmail(userData.email, newEmail, otp);
        await updateDoc(userRef, { email: newEmail });
      }

      // Update user data in the context
      setUserData({ ...userData, fullName: newName, userName: newUsername, email: newEmail });
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile. Please try again.');
    }
  };

  const handleChangePassword = async () => {
    try {
      const auth = getAuth(getFirebaseApp());
      await sendPasswordResetEmail(auth, userData.email);
      alert('Password reset email sent');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email. Please try again.');
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }
  return (
    <Layout>
      <div className="account-details">
        <h2>Account Details</h2>
        <img src={userData.profilePhotoUrl} alt="Profile" />
        <form onSubmit={handleUpdateProfile}>
          <input
            type="text"
            value={newName}
            onChange={handleNameChange}
            placeholder="Enter your name"
          />
          <input
            type="text"
            value={newUsername}
            onChange={handleUsernameChange}
            placeholder="Enter your username"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleProfilePhotoChange}
          />
          <input
            type="email"
            value={newEmail}
            onChange={handleEmailChange}
            placeholder="Enter your email"
          />
          {!otpSent && (
            <button type="button" onClick={handleSendOtp}>
              Send OTP
            </button>
          )}
          {otpSent && (
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
            />
          )}
          <button type="submit">Update Profile</button>
        </form>
        <div className="change-password">
          <button onClick={handleChangePassword}>Change Password</button>
        </div>
      </div>
    </Layout>
  );
}
import React from 'react';
import './RefundAndCancellation.css'; // Importing CSS file for styling
import logo from '../assets/Images/logo.png';

function RefundAndCancellation() {
    return (
        <div className="refund-page">
            <header className="top-bar">
                <img src={logo} alt="On Tour Logo" className="logo" />
                <button className="sign-in-btn">Sign In</button>
            </header>
            <div className="container">
                <h1 className="title">Refund and Cancellation Policy</h1>
                <div className="content">
                    <p>At Forte Technologies, we understand that circumstances may change, and you may need to cancel your subscription. Below is our refund and cancellation policy:</p>

                    <h2>Cancellation</h2>
                    <p>You can cancel your subscription at any time by visiting your account settings in the 'On Tour' App & directly from Dashboard in the website. Once cancelled, your subscription will remain active until the end of the current billing cycle.</p>

                    <h2>Refunds</h2>
                    <p>Refunds are not provided for subscription fees already paid. Once a payment is made, it is non-refundable. However, you will continue to have access to the subscription benefits until the end of the current billing cycle.</p>

                    <h2>Changes to Subscription</h2>
                    <p>You can change or upgrade your subscription plan at any time through your account settings. Any changes made will take effect immediately, and you will be charged or refunded accordingly.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our refund and cancellation policy, please contact us at <a href="mailto:support@ontour.co.in">support@ontour.co.in</a></p>
                </div>
            </div>

            <footer className="footer">
            <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
        
            </footer>
        </div>
    );
}

export default RefundAndCancellation;

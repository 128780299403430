import React from 'react';
import TopBar from './TopBar';
import LeftMenu from './LeftMenu';

const Layout = ({ children }) => {
  return (
    <div>
      <TopBar />
      <div className="content-wrapper">
        <LeftMenu />
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
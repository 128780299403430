import React, { useEffect, useState, useContext } from 'react';
import { getUserDataFromLocalStorage } from '../utils/firebaseHelper';
import ArtistPaymentInfo from './ArtistDashboard/PaymentInfo';
import CrewPaymentInfo from './CrewDashboard/PaymentInfo';
import TourManagerPaymentInfo from './TourManagerDashboard/PaymentInfo';
import SoundVendorPaymentInfo from './SoundVendorDashboard/PaymentInfo';
import VenuePaymentInfo from './VenueDashboard/PaymentInfo';
import AdminPaymentInfo from './AdminDashboard/PaymentInfo';
import UserContext from '../context/UserContext';


 

  const PaymentInfo = () => {
    const { userData } = useContext(UserContext);
  
    const renderPaymentInfo = () => {
      if (userData) {
        switch (userData.userType) {
          case 'Artist':
            return <ArtistPaymentInfo />;
          case 'Crew':
            return <CrewPaymentInfo />;
          case 'Tour Manager':
            return <TourManagerPaymentInfo />;
          case 'Sound Vendor':
            return <SoundVendorPaymentInfo />;
          case 'Venue':
            return <VenuePaymentInfo />;
          case 'Admin':
            return <AdminPaymentInfo />;
          default:
            return <div>User type not recognized.</div>;
        }
      } else {
        return <div>Loading...</div>;
      }
    };
  
    return <div>{renderPaymentInfo()}</div>;
  };
  
  export default PaymentInfo;
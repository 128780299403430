import { getFirestore, doc, setDoc, collection, query, where, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateEmail, sendPasswordResetEmail, sendSignInLinkToEmail, getAuth } from 'firebase/auth';
import { firebaseConfig, firebaseInstances, getAuthInstance, getFirebaseApp, getFirestoreInstance, getStorageInstance } from '../firebaseHelper';


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const firestore = firebaseInstances.firestore;
const storage = firebaseInstances.storage;
const auth = getAuth(app);

export const checkUsernameUniqueness = async (username) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('username', '==', username));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty;
};

export const signUp = async (formData) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
    const { uid } = result.user;
    await result.user.updateProfile({ displayName: formData.fullName });
    const userData = await createUser(formData, uid);
    // Save user data to local storage or cookies if needed
    return true;
  } catch (error) {
    const errorCode = error.code;
    let message = 'Something went wrong';
    if (errorCode === 'auth/email-already-in-use') {
      message = 'This email is already in use';
    }
    throw new Error(message);
  }
};

export const signIn = async (email, password) => {
  try {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const userData = await getUserData(user.uid);
    return userData;
  } catch (error) {
    console.error('Error during sign-in:', error);
    const errorCode = error.code;
    let message = 'Something went wrong';
    if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found') {
      message = 'Wrong email or password!';
    }
    throw new Error(message);
  }
};


const createUser = async (formData, userId) => {
  const signUpDate = new Date().toISOString();
  const userData = {
    ...formData,
    userId,
    signUpDate,
  };

  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, userData);
    console.log('User data added with ID:', userId);
    return userData;
  } catch (error) {
    console.error('Error adding document:', error);
    throw error;
  }
};

const getUserData = async (userId) => {
  try {
    const usersCollection = collection(db, 'users');
    const userQuery = query(usersCollection, where('userId', '==', userId));
    const querySnapshot = await getDocs(userQuery);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      console.log('User data:', userData);
      return userData;
    } else {
      console.log('User document does not exist for userId:', userId);
      throw new Error('User not found');
    }
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error;
  }
};

export const updateUserProfile = async (userId, updatedData) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updatedData);
    console.log('User profile updated successfully');
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};


export const sendEmailOtp = async (email) => {
  try {
    const auth = firebaseInstances.auth();
    const actionCodeSettings = {
      url: 'http://localhost:3000/verify-email',
      handleCodeInApp: true,
    };

    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    console.log('Email OTP sent successfully');
  } catch (error) {
    console.error('Error sending email OTP:', error);
    throw error;
  }
};

export const verifyEmailOtp = async (email, otp) => {
  try {
    const auth = firebaseInstances.auth();

    if (auth.isSignInWithEmailLink(otp)) {
      await signInWithEmailAndPassword(auth, email, otp);
      console.log('Email OTP verified successfully');
      return true;
    } else {
      throw new Error('Invalid email OTP');
    }
  } catch (error) {
    console.error('Error verifying email OTP:', error);
    throw error;
  }
};

export const updateUserEmail = async (currentEmail, newEmail, otp) => {
  try {
    const auth = firebaseInstances.auth();
    const user = auth.currentUser;

    const isOtpValid = await verifyEmailOtp(newEmail, otp);

    if (isOtpValid) {
      await updateEmail(user, newEmail);
      console.log('User email updated successfully');
    } else {
      throw new Error('Invalid email OTP');
    }
  } catch (error) {
    console.error('Error updating user email:', error);
    throw error;
  }
};

export const resetUserPassword = async (email) => {
  try {
    const auth = firebaseInstances.auth();
    await sendPasswordResetEmail(auth, email);
    console.log('Password reset email sent successfully');
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};

export const uploadProfilePhoto = async (file, userId) => {
  const storageRef = ref(storage, `profilePhotos/${userId}/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle upload progress if needed
      },
      (error) => {
        console.error('Error uploading file:', error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

export { getUserData };
import React, { useEffect, useState, useContext } from 'react';
import { getUserDataFromLocalStorage } from '../utils/firebaseHelper';
import ArtistDashboard from './ArtistDashboard/Dashboard';
import CrewDashboard from './CrewDashboard/Dashboard';
import TourManagerDashboard from './TourManagerDashboard/Dashboard';
import SoundVendorDashboard from './SoundVendorDashboard/Dashboard';
import VenueDashboard from './VenueDashboard/Dashboard';
import AdminDashboard from './AdminDashboard/Dashboard';
import UserContext from '../context/UserContext'

const Dashboard = () => {
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (userData) {
      console.log('User data from context:', userData);
      console.log('User type from context:', userData.userType);
    }
  }, [userData]);

  const renderDashboard = () => {
    if (!userData) {
      return <div>Loading...</div>;
    }

    console.log('User type in renderDashboard:', userData.userType);
    switch (userData.userType) {
      case 'Artist':
        return <ArtistDashboard />;
      case 'Crew':
        return <CrewDashboard />;
      case 'Tour Manager':
        return <TourManagerDashboard />
      case 'Sound Vendor':
        return <SoundVendorDashboard />;
      case 'Venue':
        return <VenueDashboard />;
      case 'Admin':
        return <AdminDashboard />;
      default:
        return <div>User type not recognized: {userData.userType}</div>;
    }
  };

  return <div>{renderDashboard()}</div>;
};

export default Dashboard;
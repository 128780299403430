import React, { useEffect, useState, useContext } from 'react';
import { getUserDataFromLocalStorage } from '../utils/firebaseHelper';
import ArtistAccountDetails from './ArtistDashboard/AccountDetails';
import CrewAccountDetails from './CrewDashboard/AccountDetails';
import TourManagerAccountDetails from './TourManagerDashboard/AccountDetails';
import SoundVendorAccountDetails from './SoundVendorDashboard/AccountDetails';
import VenueAccountDetails from './VenueDashboard/AccountDetails';
import AdminAccountDetails from './AdminDashboard/AccountDetails';
import UserContext from '../context/UserContext';


 

  const AccountDetails = () => {
    const { userData } = useContext(UserContext);
  
    const renderAccountDetails = () => {
      if (userData) {
        switch (userData.userType) {
          case 'Artist':
            return <ArtistAccountDetails />;
          case 'Crew':
            return <CrewAccountDetails />;
          case 'Tour Manager':
            return <TourManagerAccountDetails />;
          case 'Sound Vendor':
            return <SoundVendorAccountDetails />;
          case 'Venue':
            return <VenueAccountDetails />;
          case 'Admin':
            return <AdminAccountDetails />;
          default:
            return <div>User type not recognized.</div>;
        }
      } else {
        return <div>Loading...</div>;
      }
    };
  
    return <div>{renderAccountDetails()}</div>;
  };
  
  export default AccountDetails;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkUsernameUniqueness, signUp, uploadProfilePhoto } from '../utils/actions/authActions';
import './SignupPage.css';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, getAuthInstances, getFirebaseApp, getFirestoreInstance, getStorageInstance } from '../utils/firebaseHelper';

const SignupPage = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    username: '',
    profilePhoto: null,
    fullName: '',
    country: '',
    state: '',
    city: '',
    userType: '',
    userSubType: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);


  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const [isLoading, setIsLoading] = useState(false);

  const handleNext = async () => {
    if (step === 1) {
      const isUsernameUnique = await checkUsernameUniqueness(formData.username);
      if (!isUsernameUnique) {
        setError('Username already exists. Please choose a different username.');
        return;
      }
    }
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, profilePhoto: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Sign up the user
      const signupSuccess = await signUp(formData);
      if (!signupSuccess) {
        setError('Error occurred during signup. Please try again.');
        setIsLoading(false);
        return;
      }

      // Upload profile photo if provided
      if (formData.profilePhoto) {
        const userId = auth.currentUser.uid;
        const downloadURL = await uploadProfilePhoto(formData.profilePhoto, userId);
        // Handle the downloaded URL as needed
      }

      // Signup success, navigate to the desired page
      setIsLoading(false);
      navigate('/LoginPage');
    } catch (error) {
      setError('Error occurred during signup. Please try again.');
      setIsLoading(false);
    }
  };


  return (
    <div className="signup-page">
      <h2>Signup</h2>
      {error && <div className="error">{error}</div>}
      <div className="stepper">
        <div className={`step ${step === 1 ? 'active' : ''}`}>Step 1</div>
        <div className={`step ${step === 2 ? 'active' : ''}`}>Step 2</div>
        <div className={`step ${step === 3 ? 'active' : ''}`}>Step 3</div>
      </div>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="form-step">
            <label>Username:</label>
            <input type="text" name="username" value={formData.username} onChange={handleChange} />
          </div>
        )}
        {step === 2 && (
          <>
            <div className="form-step">
              <label>Profile Photo:</label>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div className="form-step">
              <label>Full Name:</label>
              <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} />
            </div>
            <div className="form-step">
              <label>Country:</label>
              <input type="text" name="country" value={formData.country} onChange={handleChange} />
            </div>
            <div className="form-step">
              <label>State:</label>
              <input type="text" name="state" value={formData.state} onChange={handleChange} />
            </div>
            <div className="form-step">
              <label>City:</label>
              <input type="text" name="city" value={formData.city} onChange={handleChange} />
            </div>
            <div className="form-step">
              <label>User Type:</label>
              <select name="userType" value={formData.userType} onChange={handleChange}>
                <option value="">Select User Type</option>
                <option value="Tour Manager">Tour Manager</option>
                <option value="Artist">Artist</option>
                <option value="Crew">Crew</option>
                <option value="Sound Vendor">Sound Vendor</option>
                <option value="Venue">Venue</option>
              </select>
            </div>
            {formData.userType === 'Artist' && (
              <div className="form-step">
                <label>User Sub Type:</label>
                <select name="userSubType" value={formData.userSubType} onChange={handleChange}>
                  <option value="">Select User Sub Type</option>
                  <option value="Vocalist">Vocalist</option>
                  <option value="Drummer">Drummer</option>
                  <option value="Guitarist">Guitarist</option>
                  <option value="Pianist">Pianist</option>
                </select>
              </div>
            )}
            {formData.userType === 'Crew' && (
              <div className="form-step">
                <label>User Sub Type:</label>
                <select name="userSubType" value={formData.userSubType} onChange={handleChange}>
                  <option value="">Select User Sub Type</option>
                  <option value="Sound Engineer">Sound Engineer</option>
                  <option value="Light Engineer">Light Engineer</option>
                  <option value="Photographer">Photographer</option>
                </select>
              </div>
            )}
          </>
        )}
        {step === 3 && (
          <>
            <div className="form-step">
              <label>Email:</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="form-step">
              <label>Password:</label>
              <input type="password" name="password" value={formData.password} onChange={handleChange} />
            </div>
          </>
        )}
        <div className="form-actions">
          {step > 1 && <button type="button" onClick={handlePrevious}>Previous</button>}
          {step < 3 && <button type="button" onClick={handleNext}>Next</button>}
          {step === 3 && (
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Creating Account...' : 'Create Account'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignupPage;
import React from 'react';
import './PrivacyPolicy.css'; // Importing CSS file for styling
import logo from '../assets/Images/logo.png';

function PrivacyPolicy() {
    return (
        <div className="privacy-page">
            <header className="top-bar">
                <img src={logo} alt="On Tour Logo" className="logo" />
                <button className="sign-in-btn">Sign In</button>
            </header>
            <div className="container">
                <h1 className="title">Privacy Policy</h1>
                <div className="content">
                    <p>Your privacy is important to us. It is Forte Technologies' policy to respect your privacy regarding any information we may collect from you across our website, https://ontour.co.in, and other sites we own and operate.</p>

                    <h2>Information We Collect</h2>
                    <p>We may collect personal information such as your name, email address, and contact details. We may also collect non-personal information such as your IP address, browser type, and device information.</p>

                    <h2>How We Use Your Information</h2>
                    <p>We use the information we collect to provide and improve our services, communicate with you, and personalize your experience. We may also use your information to send you marketing communications and for legal and safety purposes.</p>

                    <h2>Information Sharing and Disclosure</h2>
                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with trusted third parties who assist us in operating our website, conducting our business, or servicing you.</p>

                    <h2>Security</h2>
                    <p>We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h2>Changes to This Privacy Policy</h2>
                    <p>We reserve the right to update or change our Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on this page.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:support@ontour.co.in">support@ontour.co.in</a></p>
                </div>
            </div>

            <footer className="footer">
        
                <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
            </footer>
        </div>
    );
}

export default PrivacyPolicy;

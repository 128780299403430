import React, { useContext, useState, useEffect, useMemo } from 'react';
import './PaymentInfo.css';
import UserContext from '../../context/UserContext';
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';
import { getFirebaseApp } from '../../utils/firebaseHelper';
import Layout from '../Layout';
import Payment from 'payment';
import creditCardType from 'credit-card-type';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover } from 'react-icons/fa';
import cardLogo from '../../assets/Images/card-logo.png';
import netBankingLogo from '../../assets/Images/netbanking-logo.png';
import upiLogo from '../../assets/Images/upi-logo.png';

const PaymentInfo = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState(null);
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [bank, setBank] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [upiId, setUpiId] = useState('');
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const [selectedTab, setSelectedTab] = useState('card');
  const [paymentHistory, setPaymentHistory] = useState([]);

  const memoizedUserData = useMemo(() => userData, [userData]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (memoizedUserData?.userId) {
        try {
          const db = getFirestore(getFirebaseApp());
          const usersCollection = collection(db, 'users');
          const userQuery = query(usersCollection, where('userId', '==', memoizedUserData.userId));
          const querySnapshot = await getDocs(userQuery);
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const fetchedUserData = userDoc.data();
            setUserData({
              ...fetchedUserData,
              documentId: userDoc.id,
            });
  
            // Populate form fields with saved payment information
            if (fetchedUserData.paymentInfo) {
              const { method } = fetchedUserData.paymentInfo;
              if (method === 'card') {
                setCardNumber(`**** **** **** ${fetchedUserData.paymentInfo.cardLast4}`);
                setCardType(fetchedUserData.paymentInfo.cardNetwork);
              } else if (method === 'netbanking') {
                setBank(fetchedUserData.paymentInfo.bankName);
              } else if (method === 'upi') {
                setUpiId(fetchedUserData.paymentInfo.upiId);
              }
            }
          // Set the payment history data from the user document
          setPaymentHistory(fetchedUserData.paymentHistory || []);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

    fetchUserData();
  }, [memoizedUserData]);

  const savePaymentMethod = async (method, details) => {
    console.log('savePaymentMethod called with:', method, details);
    if (memoizedUserData?.documentId) {
      try {
        const db = getFirestore(getFirebaseApp());
        const userRef = doc(db, 'users', memoizedUserData.documentId);
  
        console.log('Updating user document:', memoizedUserData.documentId);
  
        await updateDoc(userRef, {
          paymentInfo: {
            method: method,
            ...details,
          },
        });
  
        console.log('User document updated successfully');
  
        setUserData((prevUserData) => ({
          ...prevUserData,
          paymentInfo: {
            method: method,
            ...details,
          },
        }));
  
        setChangePaymentMethod(false);
      } catch (error) {
        console.error('Error saving payment method:', error);
      }
    } else {
      console.error('User document ID is missing.');
    }
  };

  const validateCardNumber = (cardNumber) => {
    return Payment.fns.validateCardNumber(cardNumber);
  };

  const validateExpiryDate = (expiryDate) => {
    return Payment.fns.validateCardExpiry(expiryDate);
  };

  const validateCVV = (cvv) => {
    return Payment.fns.validateCardCVC(cvv);
  };

  const validateUpiId = (upiId) => {
    const upiPattern = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/;
    return upiPattern.test(upiId);
  };

  const handleCardNumberChange = (e) => {
    let value = e.target.value.replace(/\s/g, '');
    if (value === '') {
      setCardNumber('');
    } else {
      setCardNumber(value);
    }
  
    const cardInfo = Payment.fns.cardType(value);
    setCardType(cardInfo);
  };

  const handleExpiryDateChange = (e) => {
    let value = e.target.value;
  
    // Remove non-digit characters from the input value
    value = value.replace(/\D/g, '');
  
    if (value === '') {
      setExpiryDate('');
      return;
    }
  
    // Add a slash after the month if the input value has 2 digits
    if (value.length === 2 && !expiryDate.includes('/')) {
      value += '/';
    }
  
    // Ensure the slash is maintained when the year is entered
    if (value.length === 3 && expiryDate.includes('/')) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
  
    // Ensure the slash is maintained when the last digit of the year is entered
    if (value.length === 4 && !value.includes('/')) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
  
    // Limit the input value to 5 characters (MM/YY format)
    if (value.length > 5) {
      value = value.slice(0, 5);
    }
  
    setExpiryDate(value);
  };

  const handleCvvChange = (e) => {
    const value = e.target.value;
    setCvv(value);
  };

  const handleUpiIdChange = (e) => {
    const value = e.target.value;
    setUpiId(value);
  };

  const getCardIcon = () => {
    switch (cardType) {
      case 'visa':
        return <FaCcVisa size={54} />;
      case 'mastercard':
        return <FaCcMastercard size={54} />;
      case 'american-express':
        return <FaCcAmex size={54} />;
      case 'discover':
        return <FaCcDiscover size={54} />;
      default:
        return null;
    }
  };

  const saveCardDetails = () => {
    if (!validateCardNumber(cardNumber)) {
      alert('Invalid card number');
      return;
    }
    if (!validateExpiryDate(expiryDate)) {
      alert('Invalid expiry date');
      return;
    }
    if (!validateCVV(cvv)) {
      alert('Invalid CVV');
      return;
    }

    const cardDetails = {
      cardLast4: cardNumber.slice(-4),
      cardNetwork: creditCardType(cardNumber)[0].niceType,
    };
    savePaymentMethod('card', cardDetails);
    alert('Your payment details are saved.');
  };
  
  const saveNetBankingDetails = () => {
    const netBankingDetails = {
      bankName: bank,
      accountNumber: accountNumber,
      ifscCode: ifscCode,
    };
    savePaymentMethod('netbanking', netBankingDetails);
  };

  const saveUPIDetails = () => {
    console.log('saveUPIDetails called');
    if (!validateUpiId(upiId)) {
      alert('Invalid UPI ID');
      return;
    }
  
    const upiDetails = {
      upiId: upiId,
    };
    savePaymentMethod('upi', upiDetails);
  };

  const indianBanks = [
    'Allahabad Bank',
'Andhra Bank',  
'Axis Bank', 
'Bandan Bank', 
'Bank of Bahrain and Kuwait', 
'Bank of Baroda', 
'Bank of India', 
'Bank of Maharashtra', 
'Bassein Catholic Co-operative Bank', 
'Bhartiya Mahila Bank',
'BNP Paribas', 
'Canara Bank', 
'Catholic Syrian Bank', 
'Central Bank of India',
'City Union Bank', 
'Corporation bank', 
'Cosmos Bank', 
'DCB BANK Personal', 
'Dena Bank', 
'Deustche Bank', 
'Development Credit Bank', 
'Dhanlaxmi Bank', 
'Federal Bank',
'HDFC Bank', 
'ICICI Bank', 
'IDBI Bank', 
'Indian Bank', 
'Indian Overseas NetBanking',
'Indusind Bank', 
'ING Vysya Bank',
'J and K Bank',
'Janta Sahakari Bank',
'Karnataka Bank', 
'Karur Vysya Bank',
'Kotak Mahindra Bank',
'Lakshmi Vilas Bank',
'Mehsana Urban Co-op Bank',
'NKGSB Co-operative Bank',
'Oriental Bank Of Commerce',
'Punjab & Sind Bank',
'Punjab and Maharashtra Cooperative Bank',
'Punjab National Bank',
'Ratnakar Bank Limited',
'RBL Bank',
'Saraswat Cooperative Bank',
'Shamrao Vithal Cooperative Bank',
'South Indian Bank',
'Standard Chartered Bank',
'State Bank Of Bikaner and Jaipur',
'State Bank of Hyderabad',
'State Bank of India',
'State Bank of Mysore',
'State Bank of Patiala',
'State Bank of Travancore',
'SVC Bank',
'Syndicate Bank',
'Tamilnad Mercantile Bank',
'Tamilnadu Cooperative Bank',
'The Kalyan Janata Sahakari Bank',
'The Royal Bank of Scotland',
'TJSB Bank (Erstwhile Thane Janata Sahakari Bank)', 
'UCO Bank',
'Union Bank of India',
'United Bank Of India',
'Vijaya Bank',
'Yes Bank',
  ];

  const PaymentHistoryTable = ({ paymentHistory }) => {
    return (
      <table className="payment-history-table">
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Method</th>
            <th>Membership Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {paymentHistory.map((payment) => (
            <tr key={payment.paymentId}>
              <td>{payment.paymentId}</td>
              <td>{payment.method}</td>
              <td>{payment.membershipType}</td>
              <td>{new Date(payment.membershipStartDate).toLocaleDateString()}</td>
              <td>{new Date(payment.membershipEndDate).toLocaleDateString()}</td>
              <td>₹{payment.amount / 100}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Layout>
      <div className="payment-info">
        <h1>Payment Info</h1>

        {memoizedUserData?.paymentInfo && (
          <div className="payment-method">
            <h2>Payment Method: {memoizedUserData.paymentInfo.method}</h2>
    
    
    {memoizedUserData.paymentInfo.method === 'card' && (
      <>
        <p>Card Number: {memoizedUserData.paymentInfo.cardLast4}</p>
        <p>Card Network: {memoizedUserData.paymentInfo.cardNetwork}</p>
      </>
    )}
    {memoizedUserData.paymentInfo.method === 'netbanking' && (
      <>
        
        <p>Bank: {memoizedUserData.paymentInfo.bankName}</p>
        <p>Account Number: {memoizedUserData.paymentInfo.accountNumber}</p>
        <p>IFSC Code: {memoizedUserData.paymentInfo.ifscCode}</p>
      </>
    )}
    {memoizedUserData.paymentInfo.method === 'upi' && (
      <>
        <p>UPI ID: {memoizedUserData.paymentInfo.upiId}</p>
      </>
    )}
    
    <button onClick={() => setSelectedTab(memoizedUserData.paymentInfo.method)}>
              Change Payment Method
            </button>
          </div>
        )}

        <div className="payment-tabs">
          <button
            className={`payment-tab ${selectedTab === 'card' ? 'active' : ''}`}
            onClick={() => setSelectedTab('card')}
          >
            <img src={cardLogo} alt="Card" />
            <span>Debit/Credit Card</span>
          </button>
          <button
            className={`payment-tab ${selectedTab === 'netbanking' ? 'active' : ''}`}
            onClick={() => setSelectedTab('netbanking')}
          >
            <img src={netBankingLogo} alt="Net Banking" />
            <span>Net Banking</span>
          </button>
          <button
            className={`payment-tab ${selectedTab === 'upi' ? 'active' : ''}`}
            onClick={() => setSelectedTab('upi')}
          >
            <img src={upiLogo} className="upi-logo" alt="UPI" />
            <span>UPI</span>
          </button>
        </div>

        {selectedTab === 'card' && (
          <div className="payment-method">
            <h2>Debit/Credit Card</h2>
              <div className="card-input">
              <input
  type="text"
  value={cardNumber.replace(/\d{4}(?=.)/g, '$& ')} // Add spaces after every 4 digits
  onChange={handleCardNumberChange}
  placeholder="Enter card number"
/>
                {getCardIcon()}
              </div>
              <p>Expiry Date:</p>
              <input
                type="text"
                value={expiryDate}
                onChange={handleExpiryDateChange}
                placeholder="MM/YY"
              />
              <p>CVV:</p>
              <input
                type="number"
                value={cvv}
                onChange={handleCvvChange}
                placeholder="Enter CVV"
              />
              <button onClick={saveCardDetails}>Save Card Details</button>
            </div>
            )}

           {selectedTab === 'netbanking' && (
          <div className="payment-method">
            <h2>Net Banking</h2>
              <p>Bank:</p>
              <select value={bank} onChange={(e) => setBank(e.target.value)}>
                <option value="">Select a bank</option>
                {indianBanks.map((bankName) => (
                  <option key={bankName} value={bankName}>
                    {bankName}
                  </option>
                ))}
              </select>
              <p>Account Number:</p>
              <input
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                placeholder="Enter account number"
              />
              <p>IFSC Code:</p>
              <input
                type="text"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                placeholder="Enter IFSC code"
              />
              <button onClick={saveNetBankingDetails}>Save Net Banking Details</button>
            </div>
            )}

            {selectedTab === 'upi' && (
          <div className="payment-method">
            <h2>UPI</h2>
              <p>UPI ID:</p>
              <input
                type="text"
                value={upiId}
                onChange={handleUpiIdChange}
                placeholder="Enter UPI ID"
              />
              <button onClick={saveUPIDetails}>Save UPI Details</button>
            </div>
            )}
         
     {paymentHistory.length > 0 && (
          <div className="payment-history">
            <h2>Payment History</h2>
            <PaymentHistoryTable paymentHistory={paymentHistory} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default PaymentInfo;